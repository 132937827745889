import './App.css';
import CreateRoutes from './Routes'

function App() {
  return (
    <CreateRoutes/>
  );
}

export default App;

  
      
